import React from 'react'
import { Button, Modal } from 'react-bootstrap';
import { lang_ } from '../../components/config/language';

const App = ({msg,modal_,onClick_}) => {

    return <>
            <Modal
                centered
                animation
                autoFocus
                show={modal_}>

                <Modal.Header >
                    <Modal.Title>{lang_.labelalert1}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <li>{msg}</li>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="primary" onClick={() => { onClick_() }} >{lang_.btnalert1}</Button>
                </Modal.Footer>
            </Modal>
    </>

}

export default App