import React, { useState, useEffect } from 'react'
import ReactTable from 'react-table';
import 'react-table/react-table.css'
import { lang_ } from '../components/config/language';
import { Button, Dropdown, Form, Modal } from 'react-bootstrap';
import Loader from '../components/Loader/Loader';
import CommonAPI from '../config/CommonApi';
import { linkPrefix } from '../config/ApiUrl';
import { getConfig } from '@testing-library/react';
import ModalAlert from "../components/modal/ModalAlert";
import './index.css'
import { tablesCategory } from '../config/ModelTables';

export const App = ({ data }) => {

  const [dinamikData, setdinamikData] = useState([])
  const [usersColumns, setusersColumns] = useState([])
  const [modal_, setmodal_] = useState(false);
  const [modal_del, setmodal_del] = useState(false);
  const [inputValue1, setInputValue1] = useState('');
  const [loading, setLoading] = useState(false);
  const [modal_alert, setmodal_alert] = useState(false);
  const [responsemsg, setresponsemsg] = useState("");
  const [data_, setdata] = useState(data);
  const [cat_list, setCatlist] = useState([]);
  const [cat_list_select, setCatListSelect] = useState("");

  useEffect(() => {

    let formData = new FormData();

    formData.append("type", "getcategory");

    CommonAPI.post(linkPrefix, formData, getConfig).then((response) => {
      
      setCatlist(response.data)
      setCatListSelect(response.data.length > 0 ? response.data[0][tablesCategory.cat_name] : "")

      setdata(response.data)

      dataPrepare(response.data)

    }).catch((error) => { console.log(error) })

    formData = new FormData();

    formData.append("type", "getcategoryalt");

    CommonAPI.post(linkPrefix, formData, getConfig).then((response) => {
      setdata(response.data)
      dataPrepare(response.data)
    }).catch((error) => { console.log(error) })

  }, [])

  function dataPrepare(data_) {

    const columns = [
      {
        Header: lang_.tablesHeader1,
        accessor: 'id',
        width: (90),
        Cell: ({ value }) => <span style={{ marginLeft: 20 }}>{value}</span>
      },
      {
        Header: lang_.tablesHeader2,
        accessor: 'cat_name',
        width: (400),
        Cell: ({ value }) => <span style={{ marginLeft: 20 }}>{value}</span>
      },
      {
        Header: lang_.tablesHeader4,
        accessor: 'cat_name_alt',
        width: (400),
        Cell: ({ value }) => <span style={{ marginLeft: 20 }}>{value}</span>
      },
      {
        filterable: false,
        Header: lang_.tablesHeader3,
        Cell: row => (
          <div style={{}}>
            <Button className='btn btn-success' type="button" style={{ marginTop: -2, width: 100, textAlign: "center", height: 40 }} onClick={() => {
              setdinamikData([row.original])
              setInputValue1(row.original["cat_name_alt"])
              setmodal_(true)
            }} >
              {lang_.tablesBtn2}
            </Button>

            <Button className='btn btn-danger' type="button" style={{ marginLeft: 10, marginTop: -2, width: 70, textAlign: "center", height: 40 }} onClick={() => {
              setdinamikData([row.original])
              setInputValue1(row.original["cat_name_alt"])
              setmodal_del(true)
            }} >
              {"SİL"}
            </Button>

          </div>
        )
      }
    ]

    setdata(data_)

    setusersColumns(columns)

  }
  async function setCat() {

    setLoading(true)

    let formData = new FormData()

    if (dinamikData.length > 0) {
      formData.append("type", "updatecatalt")
      formData.append("id", dinamikData[0]["id"])
    } else {
      formData.append("type", "insertcatalt")
    }

    formData.append("cat_name", cat_list_select)
    formData.append("cat_name_alt", inputValue1)

    let response = await CommonAPI.post(linkPrefix, formData, getConfig)

    if (response.data[0]["status"]) {

      let formData = new FormData();
      formData.append("type", "getcategoryalt");

      let response = await CommonAPI.post(linkPrefix, formData, getConfig).catch((error) => { console.log(error) })

      console.log("güncelleme sonrası data alındı... ", response.data);

      dataPrepare(response.data)

    }

    setLoading(false)

    setresponsemsg(response.data[0]["msg"])

    setTimeout(() => { setmodal_alert(true) }, 250);

  }

  async function setCatDel() {

    setLoading(true)

    let formData = new FormData()
    formData.append("type", "delcat_alt")
    formData.append("id", dinamikData[0]["id"])

    let response = await CommonAPI.post(linkPrefix, formData, getConfig)

    if (response.data[0]["status"]) {

      let formData = new FormData();
      formData.append("type", "getcategoryalt");

      let response = await CommonAPI.post(linkPrefix, formData, getConfig).catch((error) => { console.log(error) })

      dataPrepare(response.data)

    }

    setLoading(false)

    setresponsemsg(response.data[0]["msg"])

    setTimeout(() => { setmodal_alert(true) }, 250);

  }

  const getTrProps = (state, rowInfo) => {
    if (rowInfo && rowInfo.row) {
      return {
        style: {
          height: '50px'
        }
      };
    }
    return {};
  };

  function input__(label, dataState, data) {
    return (
      <>
        <Form.Floating>
          <Form.Control
            placeholder={label}
            value={dataState}
            onChange={(val) => { data && data(val.target.value) }} />
          <label>{label}</label>
        </Form.Floating>
      </>
    )
  }

  return <>
    <div className="container">
      <div className="table-container">

        <Loader state_={loading} />

        <Button className='btn btn-success' type="button" style={{ marginTop: -2, width: 120, textAlign: "center", height: 40 }} onClick={() => {
          setdinamikData([])
          setInputValue1("")
          setmodal_(true)
        }} >{lang_.btn2} +</Button>

        <div style={{ widows: '100%', display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
          <ReactTable
            style={{
              width: (window.innerWidth - 500),
            }}
            getTrProps={getTrProps}
            filterable={true}
            data={data_}
            columns={usersColumns}
            pivotColumnWidth={5}
            pageSizeOptions={[10, 20, 40, 80, 120]}
            defaultPageSize={10}
            minRows={window.innerHeight / 50}
            nextText={lang_.tablesNextTxt}
            previousText={lang_.tablesPrevTxt}
            pageText={lang_.tablesPagetxt}
            rowsText={". " + lang_.tablesPage}
            showPageJump={false}
            ofText={"-"}
            noDataText={lang_.tablesNoData}
            loadingText={lang_.tablesPage}
            freezeWhenExpanded />
        </div>

        <Modal
          centered
          animation
          autoFocus
          show={modal_}>

          <Modal.Body>
            {cat_list.length > 0 && <div className="input-group-sm">
              <div className="input-group-text" style={{ height: 50, justifyContent: 'space-around', marginBottom: 20, width: 400 }}>
                <label style={{ width: 120, textAlign: 'left' }}>{lang_.label1}</label>
                <Dropdown data-bs-theme="light" style={{ width: 300 }} onSelect={(item) => { setCatListSelect(item) }}>
                  <Dropdown.Toggle variant="light" style={{ width: '100%' }} id="dropdown-split-basic">
                    {cat_list_select !== "" ? cat_list_select : cat_list[0][tablesCategory.cat_name]}
                  </Dropdown.Toggle>

                  <Dropdown.Menu style={{ width: 270 }}>
                    {cat_list.length > 0 && cat_list.map((option, index) => (
                      <Dropdown.Item style={{ width: 250 }} key={index} eventKey={option[tablesCategory.cat_name]}>
                        {option[tablesCategory.cat_name]}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>}
            {input__(lang_.labelalert3, inputValue1, setInputValue1)}
          </Modal.Body>

          <Modal.Footer>

            <Button variant="primary" onClick={() => {

              setInputValue1("")
              setdinamikData([])
              setmodal_(false)

            }} >{lang_.btnalert3}</Button>
            <Button variant="success" onClick={() => {
              setmodal_(false)
              setTimeout(() => {
                setCat()
              }, 200);
            }} >{lang_.btnalert2}</Button>

          </Modal.Footer>

        </Modal>

        <Modal
          centered
          animation
          autoFocus
          show={modal_del}>

          <Modal.Header>
            <span>
              <h4>{lang_.labelalert1}</h4>
              <span>{lang_.labelalert4}</span>
            </span>
          </Modal.Header>

          <Modal.Body>
            <span>
              <h4>{inputValue1}</h4>
            </span>
          </Modal.Body>

          <Modal.Footer>

            <Button variant="primary" onClick={() => {
              setInputValue1("")
              setdinamikData([])
              setmodal_del(false)
            }} >{lang_.btnalert3}</Button>

            <Button variant="success" onClick={() => {
              setmodal_del(false)
              setTimeout(() => {
                setCatDel()
              }, 200);
            }} >{lang_.btnalert4}</Button>

          </Modal.Footer>

        </Modal>

        <ModalAlert modal_={modal_alert} msg={responsemsg} onClick_={() => { setmodal_alert(false) }} />
      </div>
    </div>
  </>
}

export default App
