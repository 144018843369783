import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { persistStore, persistReducer } from 'redux-persist';

import userReducer from './userReducer';

const persistUserConfig = {
  key: 'root',
  storage: AsyncStorage,
  whitelist: ['userData']
}

const rootReducer = combineReducers({

  userData: persistReducer(persistUserConfig, userReducer),

})

export const store = createStore(rootReducer, applyMiddleware(thunk));
export const persistor = persistStore(store);

