export const USER_DATA = 'USER_DATA';
export const USER_DATA_EXIT = "USER_DATA_EXIT"

const addUser = (data) => {
  return {
    type: USER_DATA,
    payload: data
  }
}

const exitUser = (data) => {
  return {
    type: USER_DATA_EXIT,
    payload: data
  }
}

export const ActionsReducer_ = {
  addUser,
  exitUser
}

