import React, { useState, useEffect } from 'react'
import ReactTable from 'react-table';
import 'react-table/react-table.css'
import { lang_ } from '../components/config/language';
import { Button, Form, Modal } from 'react-bootstrap';
import Loader from '../components/Loader/Loader';
import CommonAPI from '../config/CommonApi';
import { linkPrefix } from '../config/ApiUrl';
import { getConfig } from '@testing-library/react';
import ModalAlert from "../components/modal/ModalAlert";
import './index.css'

export const App = ({ data, catRepair }) => {

  const [dinamikData, setdinamikData] = useState([])
  const [usersColumns, setusersColumns] = useState([])
  const [modal_, setmodal_] = useState(false);
  const [modal_del, setmodal_del] = useState(false);
  const [inputValue1, setInputValue1] = useState('');
  const [loading, setLoading] = useState(false);
  const [modal_alert, setmodal_alert] = useState(false);
  const [responsemsg, setresponsemsg] = useState("");
  const [data_, setdata] = useState(data);

  useEffect(() => { dataPrepare(data_) }, [])

  function dataPrepare(data_) {

    const columns = [
      {
        Header: lang_.tablesHeader1,
        accessor: 'id',
        width: (90),
        Cell: ({ value }) => <span style={{ marginLeft: 20 }}>{value}</span>
      },
      {
        Header: lang_.tablesHeader2,
        accessor: 'cat_name',
        width: (window.innerWidth - 800),
        Cell: ({ value }) => <span style={{ marginLeft: 20 }}>{value}</span>
      },
      {
        filterable: false,
        Header: lang_.tablesHeader3,
        Cell: row => (
          <div style={{}}>
            <Button className='btn btn-success' type="button" style={{ marginTop: -2, width: 100, textAlign: "center", height: 40 }} onClick={() => {
              setdinamikData([row.original])
              setInputValue1(row.original["cat_name"])
              setmodal_(true)
            }} >
              {lang_.tablesBtn2}
            </Button>

            <Button className='btn btn-danger' type="button" style={{ marginLeft: 10, marginTop: -2, width: 70, textAlign: "center", height: 40 }} onClick={() => {
              setdinamikData([row.original])
              setInputValue1(row.original["cat_name"])
              setmodal_del(true)
            }} >
              {"SİL"}
            </Button>

          </div>
        )
      }
    ]

    setdata(data_)

    setusersColumns(columns)

  }
  async function setCat() {

    setLoading(true)

    let formData = new FormData()

    if (dinamikData.length > 0) {
      formData.append("type", "updatecat")
      formData.append("id", dinamikData[0]["id"])
    } else {
      formData.append("type", "insertcat")
    }

    formData.append("cat_name", inputValue1)

    let response = await CommonAPI.post(linkPrefix, formData, getConfig)

    if (response.data[0]["status"]) {

      let formData = new FormData();
      
      formData.append("type", "getcategory");

      let response = await CommonAPI.post(linkPrefix, formData, getConfig).catch((error) => { console.log(error) })

      dataPrepare(response.data)
    }

    setLoading(false)

    setresponsemsg(response.data[0]["msg"])

    setTimeout(() => { setmodal_alert(true) }, 250);

    catRepair()
  }

  async function setCatDel() {

    setLoading(true)

    let formData = new FormData()
    formData.append("type", "delcat")
    formData.append("id", dinamikData[0]["id"])

    let response = await CommonAPI.post(linkPrefix, formData, getConfig)

    if (response.data[0]["status"]) {

      let formData = new FormData();
      formData.append("type", "getcategory");

      let response = await CommonAPI.post(linkPrefix, formData, getConfig).catch((error) => { console.log(error) })

      dataPrepare(response.data)

    }

    setLoading(false)

    setresponsemsg(response.data[0]["msg"])

    setTimeout(() => { setmodal_alert(true) }, 250);

  }

  const getTrProps = (state, rowInfo) => {
    if (rowInfo && rowInfo.row) {
      return {
        style: {
          height: '50px'
        }
      };
    }
    return {};
  };

  function input__(label, dataState, data) {
    return (
      <>
        <Form.Floating>
          <Form.Control
            placeholder={label}
            value={dataState}
            onChange={(val) => { data && data(val.target.value) }} />
          <label>{label}</label>
        </Form.Floating>
      </>
    )
  }

  return <>
    <div className="container">
      <div className="table-container">

        <Loader state_={loading} />

        <Button className='btn btn-success' type="button" style={{ marginTop: -2, width: 120, textAlign: "center", height: 40 }} onClick={() => {
          setdinamikData([])
          setInputValue1("")
          setmodal_(true)
        }} > {lang_.btn2} + </Button>

        <div style={{ widows: '100%', display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
          <ReactTable
            style={{
              width: (window.innerWidth - 500),
            }}
            getTrProps={getTrProps}
            filterable={true}
            data={data_}
            columns={usersColumns}
            pivotColumnWidth={5}
            pageSizeOptions={[10, 20, 40, 80, 120]}
            defaultPageSize={10}
            minRows={window.innerHeight / 50}
            nextText={lang_.tablesNextTxt}
            previousText={lang_.tablesPrevTxt}
            pageText={lang_.tablesPagetxt}
            rowsText={". " + lang_.tablesPage}
            showPageJump={false}
            ofText={"-"}
            noDataText={lang_.tablesNoData}
            loadingText={lang_.tablesPage}
            freezeWhenExpanded />
        </div>

        <Modal
          centered
          animation
          autoFocus
          show={modal_}>

          <Modal.Body>
            {input__(lang_.labelalert3, inputValue1, setInputValue1)}
          </Modal.Body>

          <Modal.Footer>

            <Button variant="primary" onClick={() => {

              setInputValue1("")
              setdinamikData([])

              setmodal_(false)

            }} >{lang_.btnalert3}</Button>
            <Button variant="success" onClick={() => {
              setmodal_(false)
              setTimeout(() => {
                setCat()
              }, 200);
            }} >{lang_.btnalert2}</Button>

          </Modal.Footer>

        </Modal>

        <Modal
          centered
          animation
          autoFocus
          show={modal_del}>

          <Modal.Header>
            <span>
              <h4>{lang_.labelalert1}</h4>
              <span>{lang_.labelalert4}</span>
            </span>
          </Modal.Header>

          <Modal.Body>
            <span>
              <h4>{inputValue1}</h4>
            </span>
          </Modal.Body>

          <Modal.Footer>

            <Button variant="primary" onClick={() => {
              setInputValue1("")
              setdinamikData([])
              setmodal_del(false)
            }} >{lang_.btnalert3}</Button>

            <Button variant="success" onClick={() => {
              setmodal_del(false)
              setTimeout(() => {
                setCatDel()
              }, 200);
            }} >{lang_.btnalert4}</Button>

          </Modal.Footer>

        </Modal>

        <ModalAlert modal_={modal_alert} msg={responsemsg} onClick_={() => { setmodal_alert(false) }} />
      </div>
    </div>
  </>
}

export default App
