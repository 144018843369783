import 'react-table/react-table.css'
import React, { useState, useEffect } from 'react'
import ReactTable from 'react-table';
import { lang_ } from '../components/config/language';
import { Button, Dropdown, Form, Modal } from 'react-bootstrap';
import Loader from '../components/Loader/Loader';
import CommonAPI from '../config/CommonApi';
import { linkPrefix } from '../config/ApiUrl';
import { getConfig } from '@testing-library/react';
import ModalAlert from "../components/modal/ModalAlert";
import './index.css'
import { tablesCategory } from '../config/ModelTables';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment/moment';


export const App = () => {

    const [dinamikData, setdinamikData] = useState([])
    const [usersColumns, setusersColumns] = useState([])
    const [modal_, setmodal_] = useState(false);

    const [modal_del, setmodal_del] = useState(false)
    const [inputValue5, setInputValue5] = useState('')
    const [inputValue6, setInputValue6] = useState('')
    const [inputValue9, setInputValue9] = useState('')
    const [inputValue10, setInputValue10] = useState('')
    const [inputValue11, setInputValue11] = useState('')
    const [inputValue12, setInputValue12] = useState('')
    const [loading, setLoading] = useState(false);
    const [modal_alert, setmodal_alert] = useState(false);
    const [responsemsg, setresponsemsg] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [data_, setdata] = useState([]);

    const [cat_list, setCatlist] = useState([]);
    const [cat_list_select, setCatListSelect] = useState("");

    const [cat_listalt, setCatlistalt] = useState([]);
    const [cat_list_selectalt, setCatListSelectalt] = useState("");

    const [date1, onChangeDate1] = useState(new Date());
    const [date2, onChangeDate2] = useState(new Date());
    const [date3, onChangeDate3] = useState(new Date());


    const [selectedImage, setSelectedImage] = useState(null);

    function selectImage(file) {
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setSelectedImage(reader.result);
            };
            reader.readAsDataURL(file);
            setInputValue6("")
        }
    }

    const onFileChange = (event) => {
        setSelectedFile(event.target.files[0])
        selectImage(event.target.files[0])
    };

    useEffect(() => {

        getListData()

    }, [])

    async function getListData() {

        let formCategories = new FormData()
        formCategories.append("type", "getcategory")

        let formCategoriesalt = new FormData()
        formCategoriesalt.append("type", "getcategoryaltlist")

        let kayitlar = new FormData()
        kayitlar.append("type", "getkayitlar")
        kayitlar.append("typeRegister", 1);

        const response = await Promise.all([CommonAPI.post(linkPrefix, formCategories, getConfig), CommonAPI.post(linkPrefix, formCategoriesalt, getConfig), CommonAPI.post(linkPrefix, kayitlar, getConfig)])

        setCatlist(response[0].data)
        setCatlistalt(response[1].data)
        dataPrepare(response[2].data)

        console.log("response : ", response[0].data);
        console.log("response alt: ", response[1].data);
        console.log("response kayıtlar: ", response[2].data);

    }

    function dataPrepare(data_) {

        const columns = [
            {
                Header: "id",
                accessor: 'id',
                width: (50),
                Cell: ({ value }) => <span style={{ marginLeft: 20 }}>{value}</span>
            },
            {
                Header: "islemtarihi",
                accessor: 'islemtarihi',
                width: (120),
                Cell: ({ value }) => <span style={{ marginLeft: 20 }}>{value}</span>
            },
            {
                Header: "islemsaati",
                accessor: 'islemsaati',
                width: (120),
                Cell: ({ value }) => <span style={{ marginLeft: 20 }}>{value}</span>
            },
            {
                Header: "username",
                accessor: 'username',
                width: (300),
                Cell: ({ value }) => <span style={{ marginLeft: 20 }}>{value}</span>
            },
            {
                filterable: false,
                Header: "images",
                accessor: 'images',
                width: (80),
                Cell: ({ value }) => value ? <img style={{ width: 70, height: 60 }} src={"http://192.168.0.101/meyveli-person/service/web_/uploads/" + value} alt="Açıklama" /> : <></>
            },
            {
                Header: "categori",
                accessor: 'categori',
                width: (150),
                Cell: ({ value }) => <span style={{ marginLeft: 20 }}>{value}</span>
            },
            {
                Header: "categorialt",
                accessor: 'categorialt',
                width: (150),
                Cell: ({ value }) => <span style={{ marginLeft: 20 }}>{value}</span>
            },
            {
                Header: "gorev",
                accessor: 'gorev',
                width: (200),
                Cell: ({ value }) => <span style={{ marginLeft: 20 }}>{value}</span>
            },
            {
                Header: "dogumtarihi",
                accessor: 'dogumtarihi',
                width: (120),
                Cell: ({ value }) => <span style={{ marginLeft: 20 }}>{moment(new Date(value)).format('DD.MM.YYYY')}</span>
            },
            {
                Header: "isebaslama",
                accessor: 'isebaslama',
                width: (120),
                Cell: ({ value }) => <span style={{ marginLeft: 20 }}>{moment(new Date(value)).format('DD.MM.YYYY')}</span>
            },
            {
                Header: "adres",
                accessor: 'adres',
                width: (400),
                Cell: ({ value }) => <span style={{ marginLeft: 20 }}>{value}</span>
            },
            {
                Header: "tahsil",
                accessor: 'tahsil',
                width: (120),
                Cell: ({ value }) => <span style={{ marginLeft: 20 }}>{value}</span>
            },
            {
                Header: "notes",
                accessor: 'notes',
                width: (400),
                Cell: ({ value }) => <span style={{ marginLeft: 20 }}>{value}</span>
            },
            {
                filterable: false,
                Header: lang_.tablesHeader3,
                width: (250),
                Cell: row => (
                    <div style={{}}>

                        <Button className='btn btn-success' type="button" style={{ marginTop: -2, width: 100, textAlign: "center", height: 40 }} onClick={() => {

                            setSelectedFile(null)

                            setdinamikData([row.original])

                            setCatListSelect(row.original["categori"])
                            setCatListSelectalt(row.original["categorialt"])
                            setInputValue5(row.original["username"])
                            setInputValue6(row.original["images"])

                            onChangeDate1(row.original["dogumtarihi"])
                            onChangeDate2(row.original["isebaslama"])
                            onChangeDate3(row.original["iseson"])

                            setInputValue9(row.original["adres"])
                            setInputValue10(row.original["tahsil"])
                            setInputValue11(row.original["gorev"])
                            setInputValue12(row.original["notes"])

                            setInputValue5(row.original["username"])
                            setmodal_(true)
                        }} >{lang_.tablesBtn2}</Button>

                        <Button className='btn btn-danger' type="button" style={{ marginLeft: 10, marginTop: -2, width: 100, textAlign: "center", height: 40 }} onClick={() => {
                            setdinamikData([row.original])
                            setmodal_del(true)
                        }} >{"SİL"}</Button>

                    </div>
                )
            }
        ]

        setdata(data_)

        setusersColumns(columns)

        statezero()
    }

    async function setCat() {

        setLoading(true)

        let formData = new FormData()

        if (dinamikData.length > 0) {
            formData.append("type", "kayitduzenle")
            formData.append("id", dinamikData[0]["id"])
        } else {
            formData.append("type", "kayitekle")
        }

        formData.append('islemtarihi', moment(new Date()).format('DD.MM.YYYY'))
        formData.append('islemsaati', moment(new Date()).format('HH:mm:ss'))
        formData.append('username', inputValue5)
        formData.append('file', selectedFile ? selectedFile : null)
        formData.append('categori', cat_list_select)
        formData.append('categorialt', cat_list_selectalt)
        formData.append('dogumtarihi', date1 ? date1 : "")
        formData.append('isebaslama', date2 ? date2 : "")
        formData.append('iseson', date3 ? date3 : "")
        formData.append('adres', inputValue9)
        formData.append('tahsil', inputValue10)
        formData.append('gorev', inputValue11)
        formData.append('notes', inputValue12)
        formData.append('status_', date3 === null || date3 === "" ? 1 : 0)

        let response = await CommonAPI.post(linkPrefix, formData, {
            headers: {
                'content-type': 'multipart/form-data',
            },
        })

        console.log("response :", response.data);
        if (response.data[0]["status"]) {

            let formData = new FormData();
            formData.append("type", "getkayitlar");
            formData.append("typeRegister", 1);

            let response = await CommonAPI.post(linkPrefix, formData, getConfig).catch((error) => { console.log(error) })

            console.log("güncelleme sonrası data alındı... ", response.data);

            dataPrepare(response.data)

        }

        setLoading(false)

        setresponsemsg(response.data[0]["msg"])

        setTimeout(() => { setmodal_alert(true) }, 250);

    }

    async function setCatDel() {

        setLoading(true)

        let formData = new FormData()
        formData.append("type", "kayitsil")
        formData.append("id", dinamikData[0]["id"])

        let response = await CommonAPI.post(linkPrefix, formData, getConfig)

        if (response.data[0]["status"]) {

            let formData = new FormData();
            formData.append("type", "getkayitlar");
            formData.append("typeRegister", 1);

            let response = await CommonAPI.post(linkPrefix, formData, getConfig).catch((error) => { console.log(error) })

            dataPrepare(response.data)

        }

        setLoading(false)

        setresponsemsg(response.data[0]["msg"])

        setTimeout(() => { setmodal_alert(true) }, 250);

    }

    const getTrProps = (state, rowInfo) => {
        if (rowInfo && rowInfo.row) {
            return {
                style: {
                    height: '70px'
                }
            };
        }
        return {};
    };

    function input__(label, dataState, data) {
        return (
            <>
                <Form.Floating style={{ marginBottom: 10 }}>
                    <Form.Control
                        placeholder={label}
                        value={dataState}
                        onChange={(val) => { data && data(val.target.value) }} />
                    <label>{label}</label>
                </Form.Floating>
            </>
        )
    }

    function date__(label, dataState, data) {
        return (
            <>
                <div style={{ paddingLeft: 10, display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }}>
                    <h6 style={{ marginTop: 15, marginRight: 10 }}>{label}</h6>
                    <DatePicker onClick={() => { console.log(data) }} onChange={(val) => { dataState(val) }} value={data} />
                </div>
            </>
        )
    }

    function statezero() {
        setdinamikData([])
        setInputValue5("")
        setInputValue6("")
        setInputValue9("")
        setInputValue10("")
        setInputValue11("")
        setInputValue12("")
    }

    return <>
        <div className="container">
            <div className="table-container">

                <Loader state_={loading} />


                <Button className='btn btn-success' type="button" style={{ marginLeft: 100, marginTop: 50, width: 120, textAlign: "center", height: 40 }} onClick={() => {
                    cat_list && setCatListSelect(cat_list[0][tablesCategory.cat_name])
                    cat_listalt && setCatListSelectalt(cat_listalt[0][tablesCategory.cat_name_alt])
                    statezero()
                    setmodal_(true)
                }} >{lang_.btn2} +</Button>

                <ReactTable

                    style={{
                        left: 100,
                        width: (window.innerWidth - 300),
                    }}
                    getTrProps={getTrProps}
                    filterable={true}
                    data={data_}
                    columns={usersColumns}
                    pivotColumnWidth={5}
                    pageSizeOptions={[10, 20, 40, 80, 120]}
                    defaultPageSize={10}
                    minRows={window.innerHeight / 50}
                    nextText={lang_.tablesNextTxt}
                    previousText={lang_.tablesPrevTxt}
                    pageText={lang_.tablesPagetxt}
                    rowsText={". " + lang_.tablesPage}
                    showPageJump={false}
                    ofText={"-"}
                    noDataText={lang_.tablesNoData}
                    loadingText={lang_.tablesPage}
                    freezeWhenExpanded />
                <Modal
                    centered
                    animation
                    autoFocus
                    show={modal_}>

                    <Modal.Body>

                        <div><input style={{ marginBottom: 20, }} type="file" onChange={onFileChange} /></div>

                        {console.log(inputValue6)}

                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            {inputValue6 && <img style={{ width: 150, height: 150 }} src={"http://192.168.0.101/meyveli-person/service/web_/uploads/" + inputValue6} />}

                            {selectedFile && <img style={{ width: 150, height: 150 }} src={selectedImage} />}

                            {inputValue6 && <Button className='btn btn-danger' type="button" style={{ marginLeft: 10, marginTop: -2, width: 50, textAlign: "center", height: 40 }} onClick={() => {
                                setInputValue6("")
                                setSelectedFile("")
                            }} >{"SİL"}</Button>}
                        </div>

                        {cat_list.length > 0 && <div className="input-group-sm">
                            <div className="input-group-text" style={{ height: 50, justifyContent: 'space-around', marginBottom: 20, width: 400 }}>
                                <label style={{ width: 120, textAlign: 'left' }}>{lang_.label3}</label>
                                <Dropdown data-bs-theme="light" style={{ width: 300 }} onSelect={(item) => { setCatListSelect(item) }}>
                                    <Dropdown.Toggle variant="light" style={{ width: '100%' }} id="dropdown-split-basic">
                                        {cat_list_select !== "" ? cat_list_select : cat_list[0][tablesCategory.cat_name]}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu style={{ width: 270 }}>
                                        {cat_list.length > 0 && cat_list.map((option, index) => (
                                            <Dropdown.Item style={{ width: 250 }} key={index} eventKey={option[tablesCategory.cat_name]}>
                                                {option[tablesCategory.cat_name]}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>}
                        {cat_listalt.length > 0 && <div className="input-group-sm">
                            <div className="input-group-text" style={{ height: 50, justifyContent: 'space-around', marginBottom: 20, width: 400 }}>
                                <label style={{ width: 120, textAlign: 'left' }}>{lang_.label4}</label>
                                <Dropdown data-bs-theme="light" style={{ width: 300 }} onSelect={(item) => { setCatListSelectalt(item) }}>
                                    <Dropdown.Toggle variant="light" style={{ width: '100%' }} id="dropdown-split-basic">
                                        {cat_list_selectalt !== "" ? cat_list_selectalt : cat_listalt[0][tablesCategory.cat_name_alt]}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu style={{ width: 270 }}>
                                        {cat_listalt.length > 0 && cat_listalt.map((option, index) => (
                                            <Dropdown.Item style={{ width: 250 }} key={index} eventKey={option[tablesCategory.cat_name_alt]}>
                                                {option[tablesCategory.cat_name_alt]}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                        }

                        {input__(lang_.labelalert5, inputValue5, setInputValue5)}

                        {date__("Doğum Tarihi", onChangeDate1, date1)}
                        {date__("İşe Başlama Tarihi", onChangeDate2, date2)}
                        {date__("İşten Ayrılma", onChangeDate3, date3)}

                        {input__(lang_.labelalert9, inputValue9, setInputValue9)}
                        {input__(lang_.labelalert10, inputValue10, setInputValue10)}
                        {input__(lang_.labelalert11, inputValue11, setInputValue11)}
                        {input__(lang_.labelalert12, inputValue12, setInputValue12)}

                    </Modal.Body>

                    <Modal.Footer>

                        <Button variant="primary" onClick={() => {
                            statezero()
                            setmodal_(false)
                        }} >{lang_.btnalert3}</Button>
                        <Button variant="success" onClick={() => {
                            setmodal_(false)
                            setTimeout(() => {
                                setCat()
                            }, 200);
                        }} >{lang_.btnalert2}</Button>

                    </Modal.Footer>

                </Modal>

                <Modal
                    centered
                    animation
                    autoFocus
                    show={modal_del}>

                    <Modal.Header>
                        <span>
                            <h4>{lang_.labelalert1}</h4>
                            <span>{lang_.labelalert4}</span>
                        </span>
                    </Modal.Header>

                    <Modal.Body>
                        <span>
                            <h4>{inputValue5}</h4>
                        </span>
                    </Modal.Body>

                    <Modal.Footer>

                        <Button variant="primary" onClick={() => {
                            setdinamikData([])
                            setmodal_del(false)
                        }} >{lang_.btnalert3}</Button>

                        <Button variant="success" onClick={() => {
                            setmodal_del(false)
                            setTimeout(() => {
                                setCatDel()
                            }, 200);
                        }} >{lang_.btnalert4}</Button>

                    </Modal.Footer>

                </Modal>

                <ModalAlert modal_={modal_alert} msg={responsemsg} onClick_={() => { setmodal_alert(false) }} />
            </div>
        </div>
    </>
}

export default App
