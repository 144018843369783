
import React from 'react'
import { Modal, Spinner } from 'react-bootstrap';
import { FaSpinner } from 'react-icons/fa';
import { lang_ } from '../config/language';
const Loader = ({ state_ }) => {
    return <>
        <Modal
            centered
            show={state_}>
            <Modal.Body style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', height: 200, }}>
                {
                    <div style={{ height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around' }}>
                        <FaSpinner size={40} className="fa-spin" />
                        <h4>{lang_.loaderTxt}</h4>
                    </div>
                }
            </Modal.Body>
        </Modal>
    </>
}

export default Loader