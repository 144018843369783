import React, { useEffect, useState } from 'react'

import BasicTable from './BasicTable'
import Loader from '../components/Loader/Loader'
import { Button, Modal } from 'react-bootstrap';
import CommonAPI from '../config/CommonApi';
import { linkPrefix } from '../config/ApiUrl';
import { getConfig } from '@testing-library/react';
import { lang_ } from '../components/config/language';


const App = () => {

    const [loading, setLoading] = useState(false);
    const [data, setdata] = useState([]);
    const [modal_, setmodal_] = useState(false);

    useEffect(() => { getCategory() }, [])

    const getCategory = async () => {

        setLoading(true)

        let formData = new FormData();
        formData.append("type", "getcategory");

        let response = await CommonAPI.post(linkPrefix, formData, getConfig).catch((error) => { console.log(error) })

        setdata(response.data)

        setLoading(false)

    }

    return <>

        <Loader state_={loading} />

        {data.length > 0 && < BasicTable data={data} catRepair={getCategory}  ></BasicTable >}

        <Modal
            centered
            animation
            autoFocus
            show={modal_}>

            <Modal.Header >
                <Modal.Title>{lang_.labelalert1}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <li>{lang_.labelalert1}</li>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="primary" onClick={() => { setmodal_(false) }} >KAPAT</Button>
            </Modal.Footer>
        </Modal>

    </>

}

export default App