import React from 'react';
import Sidebar from './components/Sidebar/Sidebar';
import { useSelector} from 'react-redux';
import Login from '../src/login/Login'
function App() {

  const { userData } = useSelector(state => state.userData);

  return <>
    {
      userData.length === 0 ? <Login /> : <Sidebar />
    }
  </>

}

export default App;
