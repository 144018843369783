export const lang_ = {
    //LOGİN
    btn1: "GİRİŞ",
    btn2: "EKLE",
    btnalert1: "KAPAT",
    btnalert2: "KAYDET",
    btnalert3: "VAZGEÇ",
    btnalert4: "TAMAM",
    label1: "KULLANICI ADI",
    label2: "ŞİFRE",
    label3: "KATEGORİ",
    label4: "KATEGORİ ALT",
    labelalert1: "UYARI",
    labelalert2: "KULLANICI BULUNAMADI",
    labelalert3: "KATEGORİ İSMİ GİRİN",
    labelalert4: "KAYIT SİLİNECEK. EMİNMİSİNİZ ?",
    labelalert5: "KULLANICI ADI",
    labelalert6: "RESİM SEÇ",
    labelalert7: "DOĞUM TARİHİ",
    labelalert8: "İŞE BAŞLAMA",
    labelalert9: "ADRES",
    labelalert10: "TAHSİL",
    labelalert11: "GÖREV",
    labelalert12: "NOT",
    tablesNoData: "Kayıt Bulunamadı..",
    tablesCh2: "KATEGORİ İSMİ",
    tablesPrevTxt: "Geri",
    tablesNextTxt: "İleri",
    tablesBtn1: "DETAY",
    tablesBtn2: "DÜZENLE",
    tablesPage: "Sayfa",
    tablesHeader1: "ID",
    tablesHeader2: "KATEGORİ",
    tablesHeader3: "İŞLEMLER",
    tablesHeader4: "KATEGORİ ALT",
    tablesHeader5: "KULLANICI",
    tablesHeader6: "RESİM",
    tablesHeader7: "KATEGORİ",
    tablesHeader8: "KATEGORİ ALT",
    tablesHeader9: "DOĞUM TARİHİ",
    tablesHeader10: "İŞE BAŞLAMA",
    tablesHeader11: "İŞTEN AYRILMA",
    tablesHeader12: "ADRES",
    tablesHeader12: "NOT",
    tablesHeader13: "DURUM",
    loaderTxt: "Bekleyin...",
    tablesPagetxt: "Sayfalar",

}