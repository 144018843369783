
export const leftMenuOpen = [{
    dashboard: "KAYITLAR",
    dashboardSilinenler: "SİLİNENLER",
    contact: "İletişim",
    content: "İçerik",
    language: "Dil ayarları",
    about: "Hakkımızda",
    cadAdd:"KATEGORİ EKLE",
    cadAddAlt:"ALT KATEGORİ EKLE",
    message:"Mesajlar",
    notification_:"Bildirimler",
    users:"Kullanıcılar",
    offers:"Teklifler"
}]

export const leftMenuClose = [{
    dashboard: "",
    dashboardSilinenler: "",
    contact: "",
    content: "",
    language: "",
    about: "",
    logout:"",
    message:"",
    notification_:"",
    users:"",
    offers:""
}]