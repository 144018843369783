import axios from 'axios';

const instance = axios.create()

const post = async (url, params, config) => {
    const response = await instance.post(url, params, config)
    return response;
}
const get = async (url, config) => {
    const { data } = await instance.get(url, config);
    return data;
}

const del = async => {
    return instance.delete;
}

const setBaseUrl = (url) => {
    instance.defaults.baseURL = url;
    instance.defaults.withCredentials = true;
}

const CommonAPI = {
    post,
    get,
    setBaseUrl,
    del
}
export default CommonAPI;

