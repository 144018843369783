import './index.css';

import React, { useEffect, useState } from 'react';
import { Link, NavLink, Route, Routes } from 'react-router-dom';
import { BrowserRouter as Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { leftMenuClose, leftMenuOpen } from '../../components/config/StringConfig'

import Dashboard from '../../dashboard/Dashboard';
import DashboardSilinenler from '../../dashboard/DashboardSilinenler';
import Login from '../../login/Login';
import Categories from '../../categories/Categories';
import CategoriesAlt from '../../categoriesalt/Categories';

import { ActionsReducer_ } from '../../redux/ActionsReducer';

import './index.css'

const Sidebar = () => {
  const dispatch = useDispatch()

  const [show, setShow] = useState(false);
  const { userData } = useSelector(state => state.userData);

  const [leftMenuStringStatus, setleftMenuStringStatus] = useState(leftMenuClose)

  const [screenWidth, setScreenWidth] = useState(770)

  React.useEffect(() => {
    function handleResize() {
      console.log('resized to: ', window.innerWidth, 'x', window.innerHeight)
      setScreenWidth(window.innerWidth)
      if (window.innerWidth < 768) {
        if (show) {
          setleftMenuStringStatus(leftMenuClose)
        }
      } else {
        if (show && window.innerWidth > 768) {
          setleftMenuStringStatus(leftMenuOpen)
        } else {
          setleftMenuStringStatus(leftMenuClose)
        }
      }
    }
    window.addEventListener('resize', handleResize);
  })

  React.useEffect(() => {
    if (show && screenWidth > 768) {
      setleftMenuStringStatus(leftMenuOpen)
    } else {
      setleftMenuStringStatus(leftMenuClose)
    }
    console.log(show);
  }, [])

  function sw(params) {
    if (!params && screenWidth > 768) {
      setleftMenuStringStatus(leftMenuOpen)
    } else {
      setleftMenuStringStatus(leftMenuClose)
    }
    setShow(!params)
    console.log("params", params);
  }

  return <>
    {
      userData.length > 0 && <main className={show ? 'space-toggle' : null} >
        <header className={`header ${show ? 'space-toggle' : null}`} >
          <div className='header-toggle' onClick={() => sw(show)}>
            <i className={`fas fa-bars ${show ? 'fa-solid fa-xmark' : null}`}></i>
          </div>
        </header>

        <aside className={`sidebar ${show ? 'show' : null}`}>
          <nav className='nav'>

            <div className='nav-list'>

              <NavLink to='/' className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link'}>
                <li style={{ fontSize: 14 }} className='nav-link-name leftmenutext'>{leftMenuStringStatus[0].dashboard}</li>
              </NavLink>

              <NavLink to='/silinenler' className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link'}>
                <li style={{ fontSize: 14 }} className='nav-link-name leftmenutext'>{leftMenuStringStatus[0].dashboardSilinenler}</li>
              </NavLink>

              <NavLink to='/categories' className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link'}>
                <li style={{ fontSize: 14 }} className='nav-link-name leftmenutext' onClick={() => { }}>{leftMenuStringStatus[0].cadAdd}</li>
              </NavLink>

              <NavLink to='/categoriesalt' className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link'}>
                <li style={{ fontSize: 14 }} className='nav-link-name leftmenutext' onClick={() => { }}>{leftMenuStringStatus[0].cadAddAlt}</li>
              </NavLink>

            </div>

            <NavLink to='/login' className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link'}>
              <i className='fas fa-sign-out nav-link-icon' onClick={() => {
                dispatch(ActionsReducer_.exitUser())
                console.log("click ex 1");
              }}></i>
              <span className='nav-link-name leftmenutext' onClick={() => {
                console.log("click ex 2");
                dispatch(ActionsReducer_.exitUser())
              }}>{leftMenuStringStatus[0].logout}</span>

            </NavLink>

          </nav>
        </aside>

        <Routes>
          <Route path="/" element={<Dashboard />}></Route>
          <Route path="/silinenler" element={<DashboardSilinenler />}></Route>
          <Route path="/categories" element={<Categories />}></Route>
          <Route path="/categoriesalt" element={<CategoriesAlt />}></Route>
          <Route path="/login" element={<Login />}></Route>
        </Routes>

      </main>
    }
  </>
}

export default Sidebar;
