import { USER_DATA,USER_DATA_EXIT } from './ActionsReducer';

const initialState = {
  userData: []
};

function userReducer(state = initialState, action) {
  switch (action.type) {
    case USER_DATA:
      return {
        userData: action.payload
      }
    case USER_DATA_EXIT:
      return {
        userData: []
      };
    default:
      return state;
  }
}

export default userReducer;