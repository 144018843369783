import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import CommonAPI from '../config/CommonApi';
import { linkPrefix} from '../config/ApiUrl';
import { Button, Modal, Dropdown, Card, Form } from 'react-bootstrap';
import './index.css';
import { personTables } from '../config/ModelTables';
import Loader from '../components/Loader/Loader';
import { getConfig } from '@testing-library/react';
import { lang_ } from '../components/config/language';
import { useDispatch } from 'react-redux';
import { ActionsReducer_ } from '../redux/ActionsReducer';
import  {useNavigate}  from 'react-router-dom';
function App() {

    const dispatch = useDispatch();
    const history = useNavigate()

    const [loading, setLoading] = useState(false);
    const [userlist, setuserlist] = useState([]);
    const [userlistselect, setuserlistselect] = useState("");
    const [inputValue1, setInputValue1] = useState('');
    const [modal_, setmodal_] = useState(false);

    useEffect(() => { getPersonList() }, [])

    async function getPersonList() {
        setLoading(true)

        let formData = new FormData();
        formData.append("type", "personList");

        let response = await CommonAPI.post(linkPrefix, formData, getConfig)

        setuserlist(response.data)

        setuserlistselect(response.data.length > 0 ? response.data[0][personTables.username] : "")

        console.log("personlist",response.data);
        setLoading(false)

    }

    async function login() {

        setLoading(true)

        let formData = new FormData();
        formData.append("type", "login");
        formData.append("user", userlistselect);
        formData.append("pass", inputValue1);

        let response = await CommonAPI.post(linkPrefix, formData, getConfig)

        setLoading(false)

        setTimeout(() => {
            if (response.data.length > 0) {
                dispatch(ActionsReducer_.addUser([response.data]))
                history('/',{replace: true});
            } else {
                setmodal_(true)
            }
        }, 250);

    }

    function input__(label, dataState, data, TYPE_) {
        return (
            <>
                <Form.Floating>
                    <Form.Control
                        type={TYPE_}
                        placeholder="Password"
                        value={dataState}
                        onChange={(val) => { data && data(val.target.value) }} />
                    <label>{label}</label>
                </Form.Floating>
            </>
        )
    }

    return <>

        <Loader state_={loading} />

        <div className="full-screen-container">
            <div className="centered-div" >
                {
                    userlist.length > 0 && <>
                        <Card className="text-center" style={{ width: 500, height: 300, justifyContent: 'center', alignItems: 'center' }}>
                            <div className="input-group-sm">
                                <div className="input-group-text" style={{ height: 50, justifyContent: 'space-around', marginBottom: 20, width: 400 }}>
                                    <label style={{ width: 120, textAlign: 'left' }}>{lang_.label1}</label>
                                    <Dropdown data-bs-theme="light" style={{ width: 300 }} onSelect={(item) => { setuserlistselect(item) }}>
                                        <Dropdown.Toggle variant="light" style={{ width: '100%' }} id="dropdown-split-basic">
                                            {userlistselect !== "" ? userlistselect : userlist[0][personTables.username]}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu style={{ width: 270 }}>
                                            {userlist.length > 0 && userlist.map((option, index) => (
                                                <Dropdown.Item style={{ width: 250 }} key={index} eventKey={option[personTables.username]}>
                                                    {option[personTables.username]}
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>

                            <div style={{ width: 400, flexDirection: 'column' }}>

                                {input__(lang_.label2, inputValue1, setInputValue1, "password")}

                            </div>

                            <div style={{ width: 400, marginTop: 10, display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>


                                <Button style={{ width: 250, marginRight: 10 }} variant="success" onClick={() => {
                                    login()
                                }} >{lang_.btn1}</Button>

                            </div>

                        </Card>
                    </>
                }
            </div>

            <Modal
                centered
                animation
                autoFocus
                show={modal_}>
                <Modal.Header >
                    <Modal.Title>{lang_.labelalert1}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <li>{lang_.labelalert2}</li>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => {
                        setmodal_(false)
                    }} >KAPAT</Button>
                </Modal.Footer>
            </Modal>
        </div >
    </>
}

export default App;
