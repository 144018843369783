
// const linkPrefix = "site/meyveli-person/service/web_/service_.php"
const linkPrefix = "https://www.texnoelit.com/service/web_/service_.php"


function getConfig() {
    let config
    return config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
}

export {
    linkPrefix,
    getConfig
}