const personTables = {
    username: "username",
    userpass: "userpass",
    userstatus: "userstatus",
    userrole: "userrole"
}

const tablesCategory = {
    id: "id",
    cat_name: "cat_name",
    cat_name_alt: "cat_name_alt",
}


export { personTables,tablesCategory };

