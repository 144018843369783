import React, { useEffect, useState } from 'react'

import BasicTable from './BasicTable'
import { Button, Modal } from 'react-bootstrap';
import { lang_ } from '../components/config/language';


const App = () => {

    const [loading, setLoading] = useState(false);
    const [modal_, setmodal_] = useState(false);

    return <>

        {< BasicTable data={[]}   ></BasicTable >}

        <Modal
            centered
            animation
            autoFocus
            show={modal_}>

            <Modal.Header >
                <Modal.Title>{lang_.labelalert1}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <li>{lang_.labelalert1}</li>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="primary" onClick={() => { setmodal_(false) }} >KAPAT</Button>
            </Modal.Footer>
        </Modal>

    </>

}

export default App